.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 400ms ease-in-out;
    transition-property: opacity;
    opacity: 0;
}

/* DEFAULT */

.popupWrap {
    width: calc(var(--responsive) * 560);
    height: calc(var(--responsive) * 182);
    background: #fff;
    border-radius: calc(var(--responsive) * 16);
    font-size: calc(var(--responsive) * 100);
    box-shadow: 0, 0.060em, 0.160em rgba(0, 0, 0, 0.1);
    padding: 0.460em 0.240em 0.160em;
    box-sizing: border-box;
}

.innerWrap {
    width: 100%;
    height: 100%;
}

.textWrap {
    width: 100%;
    margin-bottom: calc(var(--responsive) * 55);
}

.textWrap p {
    font-size: calc(var(--responsive) * 24);
    font-weight: 500;
    line-height: 1.208em;
    text-align: center;
}

.buttonWrap {
    display: flex;
    justify-content: center;
    width: 100%;
}

.buttonWrap button {
    width: calc(var(--responsive) * 232);
    height: calc(var(--responsive) * 36);
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: calc(var(--responsive) * 16);
    font-weight: 600;
    line-height: 1.200em;
    color: #0085ff;
}

/* _DEFAULT */

/* PRIVACY */

.privacyWrap {
    width: calc(var(--responsive) * 1160);
    height: calc(var(--responsive) * 544);
    background: #fff;
    border-radius: calc(var(--responsive) * 16);
    font-size: calc(var(--responsive) * 100);
    box-shadow: 0, 0.060em, 0.160em rgba(0, 0, 0, 0.1);
    padding: 0.510em 0.990em 0.250em;
    box-sizing: border-box;
}

.privacyInnerWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.privacyTextWrap {
    width: 100%;
    height: 83.7606%;
    overflow: hidden auto;
}

.privacyH2Wrap h2 {
    font-size: calc(var(--responsive) * 32);
    font-weight: 700;
    line-height: 1.200em;
    margin-bottom: 0.500em;
    color: #121212;
}

.privacyH2Wrap p {
    font-size: calc(var(--responsive) * 18);
    font-weight: 500;
    line-height: 1.200em;
    color: #121212;
    margin-bottom: 2.222em;
    white-space: break-spaces;
}

.privacyH3Wrap h3 {
    font-size: calc(var(--responsive) * 22);
    font-weight: 700;
    line-height: 1.200em;
    margin-bottom: 0.500em;
    color: #121212;
}

.privacyH3Wrap {
    font-size: calc(var(--responsive) * 18);
    font-weight: 500;
    line-height: 1.200em;
    color: #121212;
    margin-bottom: 2.222em;
}

.privacyH3Wrap span {
    display: block;
    margin-top: 0.80em;
    margin-left: 0.80em;
}

.privacyButtonWrap {
    display: flex;
    justify-content: center;
    width: 100%;
}

.privacySubmit,
.privacyClose {
    width: calc(var(--responsive) * 150);
    height: calc(var(--responsive) * 40);
    border: none;
    border-radius: 0.375em;
    cursor: pointer;
    font-size: calc(var(--responsive) * 16);
    font-weight: 700;
    line-height: 1.875em;
    letter-spacing: -0.02em;
}

.privacySubmit {
    background: #0085ff;
    color: #fff;
    margin-right: 0.500em;
}

.privacyClose {
    background: #cbcbcb;
    color: #fff;
}

/* _PRIVACY */


@media (max-width: 912px) {

    /* DEFAULT */

    .popupWrap {
        width: calc(var(--responsive) * 280);
        height: auto;
        border-radius: calc(var(--responsive) * 10);
        padding: 0.220em 0.240em 0.080em;
    }

    .textWrap {
        margin-bottom: calc(var(--responsive) * 26);
    }

    .textWrap p {
        font-size: calc(var(--responsive) * 15);
        line-height: 1.200em;
    }

    /* _DEFAULT */

    /* PRIVACY */
    .privacyWrap {
        width: calc(var(--responsive) * 312);
        height: max(53.125vh, 340px);
        border-radius: calc(var(--responsive) * 10);
        padding: initial;
        overflow: hidden;
    }

    .privacyTextWrap {
        width: auto;
        height: max(636.25vh, 232px);
        margin: 0.300em 0.300em 0.380em 0.290em;
    }

    .privacyH2Wrap h2 {
        font-size: calc(var(--responsive) * 22);
        margin-bottom: 0.545em;
    }

    .privacyH2Wrap p {
        font-size: calc(var(--responsive) * 15);
        font-weight: 400;
    }

    .privacyH3Wrap h3 {
        font-size: calc(var(--responsive) * 15);
        font-weight: 600;
    }

    .privacyH3Wrap {
        font-size: calc(var(--responsive) * 15);
        font-weight: 400;
    }

    .privacySubmit,
    .privacyClose {
        width: 50%;
        height: calc(var(--responsive) * 40);
        border-radius: initial;
    }

    .privacySubmit {
        margin-right: initial;
    }


    /* _PRIVACY */
    
}