.container {
    width: calc(var(--responsive) * 1194);
    margin-top: calc(var(--responsive) * 275);
    margin-bottom: calc(var(--responsive) * 323);
}

.container :global .about_card01,
.container :global .about_card02 {
    height: calc(var(--responsive) * 504);
    background: #fff;
}

:global .about_card01_title,
:global .about_card02_title {
    position: relative;
    margin-top: calc(var(--responsive) * 44);
    padding-top: calc(var(--responsive) * 18);
}

:global .about_card01_title::after,
:global .about_card02_title::after {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, -100%, 0);
    font-size: calc(var(--responsive) * 22);
    letter-spacing: -0.02em;
    line-height: 1.182em;
    font-weight: 700;
    color: #808080;
}

:global .about_card01_title::after {
    content: 'Vision';
}

:global .about_card02_title::after {
    content: 'Overview';
}

/* RESPONSIVE */
@media (max-width: 912px) {

    .container {
        width: calc(var(--responsive) * 312);
        margin-top: calc(var(--responsive) * 24);
        margin-bottom: calc(var(--responsive) * 110);
    }

    .container :global .about_card01,
    .container :global .about_card02 {
        height: calc(var(--responsive) * 410);
    }

    :global .about_card01_title,
    :global .about_card02_title {
        margin-top: calc(var(--responsive) * 20);
        padding-top: calc(var(--responsive) * 8);
    }

    :global .about_card01_title::after,
    :global .about_card02_title::after {
        font-size: calc(var(--responsive) * 20);
        line-height: 1.050em;
    }

}