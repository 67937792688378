.container {
    position: relative;
    width: 100vw;
    overflow: hidden;
    height: auto;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* SLIDES WRAP */

.slidesWrap {
    position: relative;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(109.86deg, #6D00F9 -1.56%, #FF1B1B 102.74%);
    display: flex;
    align-items: center;
}

.text_wrap {
    width: calc(var(--responsive) * 506);
    margin-left: auto;
    margin-right: calc(var(--responsive) * 27);
    white-space: break-spaces;
}

.text_wrap h3 {
    font-size: calc(var(--responsive) * 54);
    font-weight: 600;
    line-height: 1.250em;
    margin-bottom: 1.385em;
    color: #fff;
}

.text_wrap p {
    font-size: calc(var(--responsive) * 24);
    font-weight: 600;
    line-height: 1.375em;
    color: #fff;
}

.slides {
    position: relative;
    width: calc(var(--responsive) * 1026);
    height: calc(var(--responsive) * 724)
}

.devive {
    position: absolute;
    bottom: 0;
    left: calc(var(--responsive) * 221);
    width: calc(var(--responsive) * 318);
    height: calc(var(--responsive) * 698);
    z-index: 10;
}

.devive img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.devive::after {
    content: '';
    position: absolute;
    top: 10%;
    left: 12%;
    width: 77%;
    height: 58%;
    z-index: -10;
    opacity: 1;
    border: 5px solid #000;
    box-sizing: border-box;
}

/* CARD WRAP */

.cardWrap {
    position: relative;
    width: calc(var(--responsive) * 1194);
    height: auto;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: calc(var(--responsive) * 275);
    padding-bottom: calc(var(--responsive) * 275);
}

/* RESPONSIVE */
@media (max-width: 1100px) {

    .slides {
        width: calc(var(--responsive) * 764);
    }
     
}


@media (max-width: 912px) {

    /* SLIDES WRAP */

    .slidesWrap {
        height: auto;
        flex-direction: column;
    }

    .text_wrap {
        width: calc(var(--responsive) * 312);
        margin-left: auto;
        margin-right: auto;
        margin-top: calc(var(--responsive) * 80);
        margin-bottom: calc(var(--responsive) * 47);
    }

    .text_wrap h3 {
        font-size: min(calc(var(--responsive) * 32), 54px);
        font-weight: 700;
        line-height: 1.200em;
        margin-bottom: 0.750em;
    }

    .text_wrap p {
        font-size: min(calc(var(--responsive) * 15), 24px);
        font-weight: 500;
        line-height: 1.400em;
        letter-spacing: -0.02em;
    }

    .slides {
        width: min(calc(var(--responsive) * 510), 714px);
        height: min(calc(var(--responsive) * 542), 758.8px);
    }

    .devive {
        top: 0;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        width: min(calc(var(--responsive) * 213), 298.2px);
        height: min(calc(var(--responsive) * 466), 652.4px);
    }

    /* CARD WRAP */

    .cardWrap {
        width: calc(var(--responsive) * 312);
        padding-top: calc(var(--responsive) * 24);
        padding-bottom: calc(var(--responsive) * 24);
    }
    

    
}