.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.innerWrap {
    width: calc(var(--responsive) * 1194);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.innerWrap h3 {
    display: inline-block;
    text-align: center;
    width: 100%;
    font-size: calc(var(--responsive) * 54);
    line-height: 1.200em;
    font-weight: 700;
    color: #1b1b1b;
    margin-top: 0.444em;
    margin-bottom: 0.519em;
}

.innerWrap p {
    display: inline-block;
    text-align: center;
    width: 100%;
    font-size: calc(var(--responsive) * 24);
    line-height: 1.042em;
    font-weight: 600;
    color: #1b1b1b;
    margin-bottom: 2.750em;
    white-space: pre;
}

.innerWrap a {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
}

@media (hover: hover) {

    .innerWrap a:hover div span {
        animation: arrow 1400ms ease-in-out;
    }
}


@keyframes arrow {
    0% {
        transform: translate3d(0, 0, 0);
    }

    55% {
        transform: translate3d(-200%, 0, 0);
        opacity: 0;
    }

    60% {
        transform: translate3d(200%, 0, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

.innerWrap a div {
    position: relative;
    width: calc(var(--responsive) * 38);
    height: calc(var(--responsive) * 38);
    border-radius: 100%;
    border: 2px solid #0085FF;
    box-sizing: border-box;
    overflow: hidden;
}

.innerWrap a div span {
    display: block;
    font-size: calc(var(--responsive) * 38);
    width: 0.408em;
    height: 0.053em;
    background: #0085FF;
    position: absolute;
    top: calc(50% - (0.053em / 2));
    right: 0.289em;
    transition: 600ms ease-in-out;
    transition-property: transform;
}

.innerWrap a div span::after {
    content: '';
    display: block;
    font-size: 1.00em;
    width: 0.316em;
    height: 0.053em;
    background: #0085FF;
    position: absolute;
    top: calc(0.316em / 2 - 0.045em);
    right: calc(0.316em /2 - 0.04em);
    transform: rotate(45deg);
}

.innerWrap a div span::before {
    content: '';
    display: block;
    font-size: 1.00em;
    width: 0.316em;
    height: 0.053em;
    background: #0085FF;
    position: absolute;
    bottom: calc(0.316em / 2 - 0.045em);
    right: calc(0.316em /2 - 0.04em);
    transform: rotate(-45deg);
    z-index: 999999;
}

.innerWrap a span {
    display: inline-block;
    font-size: calc(var(--responsive) * 16);
    line-height: 1.563em;
    color: #0085FF;
    font-weight: 500;
    margin-left: 0.750em;
}

/* RESPONSIVE */
@media (max-width: 912px) {
    .container {
        height: calc(100vh - min(16.6666vw, 100px));
    }

    .innerWrap {
        width: calc(var(--responsive) * 312);
        margin-bottom: min(16.6666vw, 100px);
    }

    .innerWrap h3 {
        font-size: calc(var(--responsive) * 24);
        margin-bottom: 0.8em;
        margin-top: initial;
    }

    .innerWrap p {
        font-size: calc(var(--responsive) * 16);
        line-height: 1.200em;
        white-space: break-spaces;
    }

    .innerWrap a div {
        width: calc(var(--responsive) * 32);
        height: calc(var(--responsive) * 32);
        border: 1.5px solid #0085FF;
    }

    .innerWrap a div span {
        display: block;
        font-size: calc(var(--responsive) * 32);
        width: 0.408em;
        height: 0.053em;
        background: #0085FF;
        position: absolute;
        top: calc(50% - (0.053em / 2));
        right: 0.289em;
        transition: 600ms ease-in-out;
        transition-property: transform;
    }

    .innerWrap a div span::after {
        right: calc(0.316em /2 - 0.01em);
        top: calc(0.316em / 2 - 0.055em);
    }
    
    .innerWrap a div span::before {
        right: calc(0.316em /2 - 0.01em);
        bottom: calc(0.316em / 2 - 0.055em);
    }



}