.container {
    margin-top: calc(var(--responsive) * 120);
}

.item {
    height: calc(var(--responsive) * 350);
    box-sizing: border-box;
}

.item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

:global div.swiper-slide-active {
    opacity: 0.3;
}

/* RESPONSIVE */
@media (max-width: 912px) {

    .container {
        margin-top: min(calc(var(--responsive) * 63), 88.2px);
    }

    .item {
        height: min(calc(var(--responsive) * 234), 327.6px);
    }
}