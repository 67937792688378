.container {
    width: calc(var(--responsive) * 1194);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: calc(var(--responsive) * 335);
}

.title {
    width: 100%;
    font-size: calc(var(--responsive) * 54);
    line-height: 1.200em;
    font-weight: 700;
    margin-bottom: 1.778em;
}

.inputWrap:not(.userMessage, .submit) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
} 

.inputLabel {
    width: calc(var(--responsive) * 124);
    font-size: calc(var(--responsive) * 24);
    line-height: 1.200em;
    font-weight: 600;
    letter-spacing: -0.02em;
}

.inputWrap:not(.userContact, .submit) input {
    width: calc(var(--responsive) * 1046);
    height: calc(var(--responsive) * 40);
    border-radius: calc(var(--responsive) * 6);
    border: 0.3px solid #999;
    font-size: calc(var(--responsive) * 18);
    font-weight: 500;
    padding: 0.556em;
    box-sizing: border-box;
}

.inputWrap textarea {
    width: calc(var(--responsive) * 1046);
    height: calc(var(--responsive) * 284);
    border-radius: calc(var(--responsive) * 6);
    border: 0.3px solid #999;
    resize: none;
    font-size: calc(var(--responsive) * 18);
    line-height: 1.200em;
    font-weight: 500;
    padding: 0.556em;
    box-sizing: border-box;
}

.inputWrap input::placeholder {
    color: #999;
}

.userName,
.userEmail,
.userSubject {
    margin-bottom: calc(var(--responsive) * 20);
}

.userContact {
    position: relative;
    margin-bottom: calc(var(--responsive) * 40);
}

.userContact div {
    display: flex;
}

.userContact::after {
    content: '';
    width: 100%;
    position: absolute;
    border: 1px solid #999;
    left: 0;
    bottom: calc(var(--responsive) * -20);
    opacity: .4;

}

.userContact select {
    width: calc(var(--responsive) * 186);
    height: calc(var(--responsive) * 40);
    border-radius: calc(var(--responsive) * 6);
    border: 0.3px solid #999;
    padding: initial;
    box-sizing: border-box;
    margin-right: calc(var(--responsive) * 16);
    margin-left: auto;
    cursor: pointer;
    font-size: calc(var(--responsive) * 18);
    line-height: 1.200em;
    font-weight: 500;
    padding: 0.556em;
    appearance: none;
    -webkit-appearance: none;
    background-image: url(../../assets/img/ic_expended.svg);
    background-size: 1.111em;
    background-repeat: no-repeat;
    background-position: calc(100% - 0.556em) center;
}

.userContact input {
    width: calc(var(--responsive) * 844);
    height: calc(var(--responsive) * 40);
    border-radius: calc(var(--responsive) * 6);
    border: 0.3px solid #999;
    font-size: calc(var(--responsive) * 18);
    line-height: 1.200em;
    font-weight: 500;
    padding: 0.556em;
    box-sizing: border-box;
}

.userMessage {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(var(--responsive) * 12);
}

.submit {
    display: flex;
    justify-content: space-between;
    width: calc(var(--responsive) * 1046);
    margin-left: auto;
}

.privacyWrap {
    display: flex;
    align-items: center;
}

.privacyWrap label {
    font-size: calc(var(--responsive) * 16);
    font-weight: 400;
    line-height: 1.875em;
    letter-spacing: -0.02em;
    cursor: pointer;
}

.privacyWrap input {
    width: calc(var(--responsive) * 14);
    height: calc(var(--responsive) * 14);
    margin: calc(var(--responsive) * 5);
    cursor: pointer;
}

.submitBtn {
    width: calc(var(--responsive) * 150);
    height: calc(var(--responsive) * 40);
    text-align: center;
    line-height: calc(var(--responsive) * 40);
    font-size: calc(var(--responsive) * 16);
    font-weight: 600;
    letter-spacing: -0.02em;
    color: #fff;
    background: #0085ff;
    border: none;
    border-radius: calc(var(--responsive) * 6);
    cursor: pointer;
}

/* RESPONSIVE */
@media (max-width: 912px) {
    .container {
        width: calc(var(--responsive) * 312);
        margin-bottom: calc(var(--responsive) * 152);
        /* overflow: hidden; */
    }

    .title {
        font-size: calc(var(--responsive) * 32);
        margin-bottom: 0.750em;
    }

    .inputWrap:not(.userMessage, .submit) {
        flex-direction: column;
        justify-content: initial;
        align-items: flex-start;
    }

    .inputLabel {
        width: 100%;
        font-size: calc(var(--responsive) * 20);
        margin-bottom: 0.400em;
    }

    .inputWrap:not(.userContact, .submit) input {
        width: 100%;
        border: 0.3px solid #999;
        font-size: calc(var(--responsive) * 16);
        padding: 0.733em 1.067em;
    }

    .inputWrap textarea {
        width: 100%;
        height: calc(var(--responsive) * 196);
        font-size: calc(var(--responsive) * 16);
        padding: 0.733em 1.067em;
    }

    .userName,
    .userEmail,
    .userSubject {
        margin-bottom: calc(var(--responsive) * 24);
    }

    .userContact {
        margin-bottom: calc(var(--responsive) * 48);
    }

    .userContact::after {
        bottom: calc(var(--responsive) * -24);
    }

    .userContact select {
        width: calc(var(--responsive) * 86);
        margin-right: calc(var(--responsive) * 8);
        margin-left: initial;
        font-size: calc(var(--responsive) * 16);
        padding: 0.733em 1.067em;
        background-size: 1.333em;
        background-position: calc(100% - 0.667em) center;
        background-color: #fff;
    }

    .userContact input {
        width: calc(var(--responsive) * 218);
        font-size: calc(var(--responsive) * 16);
        padding: 0.733em 1.067em;
    }

    .userMessage {
        flex-direction: column;
        justify-content: initial;
        margin-bottom: calc(var(--responsive) * 8);
    }

    .submit {
        flex-direction: column;
        justify-content: initial;
        width: 100%;
        margin-left: initial;
    }

    .privacyWrap {
        margin-bottom: calc(var(--responsive) * 24);
    }
    
    .privacyWrap label {
        line-height: 2.000em;
    }

    .submitBtn {
        width: 100%;
        height: calc(var(--responsive) * 40);
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        border-radius: 0.375em;
        -webkit-border-radius: 0.375em;
        -moz-border-radius: 0.375em;
    }

}