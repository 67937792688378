.container {
    width: 100%;
    height: auto;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: pageS_OP 1200ms ease-in-out 1;
}

.innerWrap {
    width: calc(var(--responsive) * 1194);
}

@keyframes pageS_OP {
    from { opacity: 0; }
    to { opacity: 1; } 
}

/* RESPONSIVE */
@media (max-width: 912px) {
    .innerWrap {
        width: calc(var(--responsive) * 312);
    }    
}