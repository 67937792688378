.container {
    position: relative;
    width: 100vw;
    height: 400vh;
}

.innetWrap {
    position: sticky;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.innetWrap h3 {
    width: calc(var(--responsive) * 1194);
    font-size: calc(var(--responsive) * 60);
    font-weight: 700;
    text-align: center;
    opacity: 0;
}

/* RESPONSIVE */
@media (max-width: 912px) {
    .innetWrap h3 {
        width: calc(var(--responsive) * 312);
        font-size: min(calc(var(--responsive) * 46), 60px);
        line-height: 1.200em;
        white-space: break-spaces;
    }
}