.container {
    width: 100vw;
    height: 219px;
    background: #1F2A35;
    display: flex;
    justify-content: center;
    align-items: center;
}

.innerWrap {
    width: calc(var(--responsive) * 1194);
}

.company, 
.address, 
.service {
    display: flex;
}

.contentWrap {
    display: flex;
}

.company {
    margin-top: 3px;
    margin-bottom: 17px;
}

.address {
    margin-bottom: 12px;
}

.service {
    margin-bottom: 31px;
}

.innerWrap p,
.innerWrap a,
.innerWrap address{
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
}

.innerWrap a {
    color: #b0b0b0;
}

.company01 {
    font-weight: 600;
    margin-right: 33px;
}

.company02 {
    margin-right: 26px;
}

.company03 {
    margin-right: 6px;
}

.address01 {
    margin-right: 49px;
}

.service01 {
    margin-right: 24px;
}

.service02 {
    margin-right: 13px;
}

.service03 {
    margin-right: 17px;
}

@media (max-width: 912px) {

    .container {
        width: 100vw;
        height: auto;
        background: #1F2A35;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: calc(var(--responsive) * 60) calc(var(--responsive) * 24);
        box-sizing: border-box;
    }

    .innerWrap {
        width: calc(var(--responsive) * 312);
        display: flex;
        flex-direction: column;
    }

    .company,
    .address,
    .service {
        display: flex;
        flex-wrap: wrap;
    }

    .contentWrap {
        display: flex;
        flex-direction: column;
    }

    .contnetTitle {
        width: calc(var(--responsive) * 47);
        margin-right: calc(var(--responsive) * 22);
    }

    .company {
        margin-top: initial;
        margin-bottom: calc(var(--responsive) * 12.5);
    }

    .address {
        margin-bottom: calc(var(--responsive) * 12.5);
    }
    
    .service {
        margin-bottom: calc(var(--responsive) * 39.5);
    }

    .innerWrap p,
    .innerWrap a,
    .innerWrap address {
        font-size: calc(var(--responsive) * 12);
        line-height: 1.200em;
        white-space: break-spaces;
    }

    .company02, .service02, .service03 {
        margin-bottom: calc(var(--responsive) * 6.19);
    }

    /* EN, JP */

    .container.otherLang :is(.company, .address, .service) {
        flex-direction: column;
        margin-bottom: calc(var(--responsive) * 30);
    }

    .container.otherLang .contnetTitle {
        width: auto;
        margin-right: initial;
        margin-bottom: calc(var(--responsive) * 12);
    }

    .container.otherLang :is(.company02, .service02, .service03) {
        margin-bottom: calc(var(--responsive) * 4);
    }

}