.container {
  width: 100%;
  height: auto;
  text-align: center;
  border-top: 1px solid #2459a9;
  border-bottom: 1px solid #aec4e5;
}

.container caption {
  color: #787878;
  caption-side: bottom;
  text-align: end;
  font-size: calc(var(--responsive) * 12);
  letter-spacing: -0.02em;
  padding-top: 0.833em;
}

.container td {
  vertical-align: middle;
  height: calc(var(--responsive) * 26);
  font-size: calc(var(--responsive) * 12);
  line-height: 1.167em;
  letter-spacing: -0.02em;
  font-weight: 400;
  white-space: break-spaces;
  padding: 0.4em 0.3em;
}

.R1 td {
  font-weight: 600;
}

.R1 .C2 {
  border-right: none;
}

.C1,
.C2,
:where(.R7, .R8) .C3 {
  border-right: 1px solid #aec4e5;
}

:where(.R2, .R3, .R4, .R5, .R6, .R7, .R8, .R9, .R10, .R11, .R12) td {
  border-top: 1px solid #aec4e5;
}

/* RESPONSIVE */
@media (max-width: 912px) {
  .container td {
    vertical-align: middle;
    height: auto;
    font-size: calc(var(--responsive) * 13);
    line-height: 1.231em;
  }

  .container td:where(.C3, .C4) {
    font-size: calc(var(--responsive) * 12);
  }

  :where(.R8, .R9) {
    height: calc(var(--responsive) * 36);
  }
}
