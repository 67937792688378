.container {
    width: 100%;
    height: auto;
    background: linear-gradient(180deg, #FFFFFF 0%, #EEEEEE 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: pageS_OP 1200ms ease-in-out 1;
}

@keyframes pageS_OP {
    from { opacity: 0; }
    to { opacity: 1; } 
}