.container {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70px;
    height: 76px;
    background: #FBFBFD;
    border-radius: 3px;
    border: 1px solid #A3A3A3;
    cursor: pointer;
    box-sizing: border-box;
}

.container button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.container button span {
    text-align: start;
    font-size: 10px;
    font-weight: 800;
    color: #999;
    margin-left: 16px;
    margin-right: auto;
}

.container .active span{
    color: #565656;
}

.container :global button.language_ko {
    margin-top: 8px;
}

.container :global button.language_en {
    margin-bottom: 8px;
}

@media (max-width: 912px) {
    .container {
        top: initial;
        bottom: calc(var(--responsive) * 6);
        left: calc(var(--responsive) * 16);
        transform: translate3d(0, 100%, 0);
        width: calc(var(--responsive) * 105);
        height: calc(var(--responsive) * 114);
        border-radius: calc(var(--responsive) * 4.5);
    }

    .container button {
        height: calc(var(--responsive) * 30);
    }

    .container button span {
        font-size: calc(var(--responsive) * 15);
        margin-left: calc(var(--responsive) * 24);
        font-weight: 600;
    }

}