.container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 52px;
    background: #fff;
    z-index: 100;
    border-bottom: 1px solid #E0E0E0;
}

.mobileHeaderWrap {
    margin-right: auto;
    margin-left: calc(var(--responsive) * 302);
}

.logo {
    width: 90px;
}

.logoPc {
    display: block;
}

.logoMo,
.mobileBtn {
    display: none;
}

.logo img {
    width: 100%;
}

.menuWrap {
    display: flex;
}

.nav {
    display: flex;
    align-items: center;
}

.nav a.about {
    margin-right: calc(var(--responsive) * 110);
}

.nav a:not(.about) {
    margin-right: calc(var(--responsive) * 55);
}

.home, .about, .specs {
    font-size: 12px;
    font-weight: 400;
    color: #000;
    text-decoration: none;
}

.home.active, 
.about.active, 
.specs.active {
    font-weight: 600;
    text-decoration: underline;
    text-underline-offset: 1px;
}

.language {
    position: relative;
    margin-right: calc(var(--responsive) * 384);
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 800;
    z-index: 999;
}

.language_text {
    color: #565656;
    text-align: start;
    margin-right: 4px;
    pointer-events: none;
}

.languages_ico {
    width: 20px;
    pointer-events: none;
}

.polygon_ico {
    width: 20px;
    pointer-events: none;
}

.polygon_ico.active {
    transform: rotate(180deg);
}

@media (max-width: 912px) {

    /* INIT */

    :is(.nav a.about, .nav a:not(.about), .language ) {
        margin-right: initial;
    }

    /* _INIT */

    .container {
        width: 100vw;
        height: min(calc(var(--responsive) * 60), 100px);
        justify-content: center;
    }

    .mobileHeaderWrap {
        position: relative;
        width: 100%;
        height: 100%;
        margin: initial;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #E0E0E0;
        background: #FBFBFD;
        box-sizing: border-box;
        z-index: 900;
    }

    .logo {
        width: min(calc(var(--responsive) * 21.7), 36px);
    }

    .logoPc {
        display: none;
    }

    .logoMo,
    .mobileBtn {
        display: block;
    }

    .mobileBtn {
        position: absolute;
        left: calc(var(--responsive) * 24);
        background: none;
        border: none;
        width: calc(var(--responsive) * 24);
        height: calc(var(--responsive) * 24);
        cursor: pointer;
        padding: 0;
    }

    .mobileBtn img {
        width: 100%;
    }

/* mobileBtnIco */

.mobileBtnIco {
    position: relative;
    width: 100%;
    height: 100%;
}

.bar01Wrap,
.bar02Wrap {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 400ms ease-in-out;
    transition-delay: 100ms;
    transition-property: transform;
}

.bar01Wrap::after,
.bar02Wrap::after {
    content: '';
    width: 66.6666%;
    height: 6.25%;
    background: #545454;
}

.active .bar01Wrap {
    transform: rotate(45deg);
}

.inactive .bar01Wrap::after {
    animation: close-ani01 200ms ease-in-out 500ms forwards;
}

.active .bar02Wrap {
    transform: rotate(-45deg);
}

.inactive .bar02Wrap::after {
    animation: close-ani02 200ms ease-in-out 500ms forwards;
}

@keyframes close-ani01 {
    0%{
        transform: translate3d(0, 0, 0);
    }
    100%{
        transform: translate3d(0, calc(var(--responsive) * -3), 0);
    }   
}

@keyframes close-ani02 {
    0%{
        transform: translate3d(0, 0, 0);
    }
    100%{
        transform: translate3d(0, calc(var(--responsive) * 3), 0);
    }
}


/* _mobileBtnIco */
    .menuWrap {
        position: absolute;
        top: min(calc(var(--responsive) * 60), 100px);
        /* top: 60px; */
        width: 100%;
        height: calc(100vh - 52px);
        justify-content: center;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(24px);
        -webkit-backdrop-filter: blur(24px);
        transform: translate3d(0, -100%, 0);
        transition: 400ms ease-in-out;
        transition-property: transform, opacity;
        z-index: 500;
    }

    .active {
        transform: translate3d(0, 0, 0);
    }

    .nav {
        flex-direction: column;
        align-items: flex-start;
        width: calc(var(--responsive) * 300);
    }

    .home, .about, .specs {
        width: 100%;
        font-size: calc(var(--responsive) * 20);
        padding: 0.400em 0 0.400em 0.800em ;
        border-bottom: 1px solid transparent;
        box-sizing: border-box;
    }

    .active .home, .active .about, .active .specs {
        animation: gnb-text-ani 650ms ease-in-out forwards; 
    }

    .active .home {
        animation-delay: 0ms;
    }

    .active .about {
        animation-delay: 50ms;
    }

    .active .specs {
        animation-delay: 100ms;
    }

    @keyframes gnb-text-ani {
        0% {
            padding: 0.400em 0 0.400em 0.800em ;
            opacity: 0;
            border-color: transparent;
            color: transparent;
        }
        100% {
            padding: 0.900em 0 0.900em 0.800em ;
            opacity: 1;
            color: #000;
            border-color: #999;
        }
    }

    .language {
        width: 100%;
        font-size: calc(var(--responsive) * 16);
        padding: 0.438em 0 0.813em 0.500em;
        font-weight: 600;
        box-sizing: border-box;
    }

    .active .language {
        animation: gnb-text-ani2 650ms ease-in-out 150ms forwards; 
    }

    @keyframes gnb-text-ani2 {
        0% {
            padding: 0.438em 0 0.813em 0.500em;
            opacity: 0;
            color: transparent;
        }
        100% {
            padding: 0.938em 0 0.813em 0.500em;
            opacity: 1;
            color: #565656;
        }
    }

    .language_text {
        margin: 0.438em 0.375em 0.438em 0;
    }
    
    .languages_ico {
        width: calc(var(--responsive) * 30);
    }
    
    .polygon_ico {
        width: calc(var(--responsive) * 30)
    }
    
}