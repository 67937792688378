.container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleWrap {
    width: 100%;
    border-bottom: 1px solid #999;
}

.titleWrap h3 {
    width: 100%;
    text-align: start;
    font-size: calc(var(--responsive) * 54);
    font-weight: 700;
    line-height: 1.200em;
    margin-top: 1.704em;
    margin-bottom: 0.815em;
}

.imgWrap {
    width: 100%;
    margin-top: calc(var(--responsive) * 76);
    margin-bottom: calc(var(--responsive) * 108);
}

.imgWrap img {
    width: 100%;
    object-fit: cover;
}

/* RESPONSIVE */
@media (max-width: 912px) {
    
    .titleWrap h3 {
        font-size: calc(var(--responsive) * 32);
        margin-top: 1.844em;
        margin-bottom: 0.531em;
    }

    .imgWrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: calc(var(--responsive) * 76);
        margin-bottom: calc(var(--responsive) * 32);
        overflow: hidden;
    }

    .imgWrap img:first-child {
        width: 175.9615%;
    }

    .imgWrap img:last-child {
        margin-top: calc(var(--responsive) * 41);
        width: 201%;
    }
}