.container {
    width: calc(var(--responsive) * 1194);
}

.title {
    width: 100%;
    margin-bottom: calc(var(--responsive) * 96);
}

.title h3 {
    display: inline-block;
    width: 100%;
    font-size: calc(var(--responsive) * 54);
    line-height: 1.200em;
    font-weight: 700;
}

.historyWrap {
    width: 100%;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    margin-bottom: calc(var(--responsive) * 323);
    display: flex;
}

.upToDate {
    width: calc(var(--responsive) * 161);
}

.upToDateMoblie {
    display: none;
}

.upToDate h4 {
    display: inline-block;
    width: 100%;
    font-size: calc(var(--responsive) * 24);
    line-height: 1.200em;
    letter-spacing: -0.02em;
    font-weight: 600;
    padding-top: calc(var(--responsive) * 45);
    color: #0085FF;
}

.historyWrap ul {
    width: calc(var(--responsive) * 1039);
}

.historyWrap ul li {
    width: auto;
    display: flex;
    padding-top: calc(var(--responsive) * 45);
    padding-bottom: calc(var(--responsive) * 47);
    padding-left: calc(var(--responsive) * 102);
    border-bottom: 1px solid #999;
}

.historyWrap ul li:last-child {
    border-bottom: none;
}


.year {
    width: calc(var(--responsive) * 81);
    margin-right: calc(var(--responsive) * 38);
}

.year h4 {
    display: inline-block;
    font-size: calc(var(--responsive) * 24);
    line-height: 1.200em;
    letter-spacing: -0.02em;
    font-weight: 600;
    color: #1b1b1b;
}

.content {
    width: auto;
}

.content p {
    display: inline-block;
    font-size: calc(var(--responsive) * 16);
    line-height: 1.875em;
    letter-spacing: -0.02em;
    font-weight: 400;
    white-space: break-spaces;
}

/* RESPONSIVE */
@media (max-width: 912px) {
    .container {
        width: calc(var(--responsive) * 312);
    }

    .title {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: calc(var(--responsive) * 24);
    }

    .title h3 {
        width: initial;
        font-size: calc(var(--responsive) * 32);
    }

    .upToDateMoblie {
        display: inline-block;
        font-size: calc(var(--responsive) * 20);
        font-weight: 600;
        letter-spacing: -0.02em;
        color: #0085FF;
    }

    .upToDate {
        display: none;
    }

    .historyWrap {
        border-bottom: none;
        margin-bottom: calc(var(--responsive) * 110);
    }

    .historyWrap ul {
        width: 100%
    }

    .historyWrap ul li {
        padding-top: calc(var(--responsive) * 24);
        padding-bottom: calc(var(--responsive) * 24);
        padding-left: initial;
        border-bottom: 1px solid #999;
    }

    .year {
        width: calc(var(--responsive) * 66);
        margin-right: calc(var(--responsive) * 12);
    }

    .year h4 {
        font-size: calc(var(--responsive) * 20);
        padding-top: 0.250em;
    }
    
    .content p {
        width: 234px;
        font-size: calc(var(--responsive) * 15);
        line-height: 2.00em;
    }
    
    
}
