/* Fonts */
* {
	font-family: 'Pretendard', 'Pretendard JP' !important;
	color: #000;
}

/* APP */

.App {
	padding-top: 52px;
}


/* RESPONSIVE */

:root {
	/* 기준 1920에 1px, min 1280px, max 1920px */
	--responsive: clamp(0.66666px, 0.052083vw, 1px);
}

@media (max-width: 912px) {
	:root {
		/* 기준 360px에 1px, min 320px, max 912px */
		--responsive: clamp(0.888888px, 0.27777777777vw, 2.533333px);
	}

	.App {
		padding-top: min(calc(var(--responsive) * 60), 100px);
	}
}