.container {
    position: relative;
    width: 100vw;
    /* 높이값 유동적으로 수정 */
    height: 700vh;
    background: transparent;
    margin-top: -52px;
    scroll-behavior: smooth;
}

.innerWrap {
    position: sticky;
    top: 0;
    width: 100vw;
    height: 100vh; /* IOS Height 이슈 대응 예정 */
}

.canvas {
    position: absolute;
    top: 52px;
    left: 0;
    width: 100%;
    height: calc(100% - 52px);
    animation: canvasS_OP 1200ms ease-in-out 1;
}

@keyframes canvasS_OP {
    from { opacity: 0; }
    to { opacity: 1; } 
}

.canvasText {
    position: absolute;
    top: 180px;
    z-index: 100;
    width: 100%;
    display: flex;
    justify-content: center;
}

.canvasText h3 {
    width: calc(var(--responsive) * 1194);
    text-align: center;
    font-weight: 700;
    opacity: 0;
}

.canvasText .text01 {
    font-size: calc(var(--responsive) * 60);
    line-height: 1.317em;
}

.canvasText .text02 {
    font-size: calc(var(--responsive) * 54);
    line-height: 1.463em;
}

.canvasText .text02 span {
    font-size: calc(var(--responsive) * 72);
    line-height: 1.097em;
    letter-spacing: 0.3em;
}

/* RESPONSIVE */
@media (max-width: 912px) {

    .container {
        margin-top: min(calc(var(--responsive) * -60), -100px);
    }

    .canvas {
        top: min(calc(var(--responsive) * 60), 100px);
        height: 100%;
    }

    .canvasText h3 {
        width: calc(var(--responsive) * 312);
    }

    .canvasText .text01 {
        font-size: min(calc(var(--responsive) * 32), 60px);
        line-height: 1.200em;
    }

    .canvasText .text02 {
        font-size: min(calc(var(--responsive) * 32), 54px);
        line-height: 1.200em;
    }

    .canvasText .text02 span {
        display: inline-block;
        font-size: min(calc(var(--responsive) * 46), 72px);
        line-height: 1.200em;
        margin-top: 0.435em;
    }

    .container.otherLang .canvasText .text02 span{
        font-size: min(calc(var(--responsive) * 40), 72px);
    }

    
}

@media (max-width: 425px) {
    .canvasText {
        top: calc(var(--responsive) * 145);
    }
}