/* COMMON */
.container {
    width: 100%;
    position: relative;
    background: #fff;
    font-size: calc(var(--responsive) * 100);
    height: calc(var(--responsive) * 626);
    border-radius: calc(var(--responsive) * 30);
    box-shadow: 0 0.060em 0.160em rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: calc(var(--responsive) * 46);
    overflow: hidden;
    transform: translateZ(0);
    will-change: transform;
    cursor: pointer;
}

.cardBtn {
    position: absolute;
    left: calc(var(--responsive) * 48);
    bottom: calc(var(--responsive) * 48);
    width: calc(var(--responsive) * 38);
    height: calc(var(--responsive) * 38);
    border-radius: 100%;
    border: 2px solid #0085FF;
    background: transparent;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    padding: initial;
}

.cardBtn span {
    position: relative;
    display: flex;
    width: calc(var(--responsive) * 16);
    height: calc(var(--responsive) * 16);
    transition: 400ms ease-in-out;
    transition-property: transform;
}

@media (hover: hover) {

    .cardBtn:hover span {
        transform: rotate(45deg);
    }
}

.textWrap {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transition: 400ms linear;
    transition-property: backdrop-filter;
    animation: item1S_OP 1200ms ease-in-out 1;
    
}

@keyframes item1S_OP {
    from { opacity: 0; }
    to { opacity: 1; } 
}

.wrapInner {
    width: 100%;
    height: auto;
    margin: calc(var(--responsive) * 48);
}

.cardBtn span::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 2px;
    height: 100%;
    background: #0085FF;
}

.cardBtn span::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate3d(0, -50%, 0);
    width: 100%;
    height: 2px;
    background: #0085FF;
}

.inactive {
    border-color: #565656;
}

.inactive span {
    transform: rotate(45deg);
}

.inactive span::before,
.inactive span::after {
    background: #565656;
}

/* TEXT FRONT */

.textWrap :global div.card-front[class$='_title'] {
    width: 100%;
    text-align: start;
    font-size: calc(var(--responsive) * 54);
    line-height: 1.200em;
    letter-spacing: -0.02em;
    font-weight: 700;
    box-sizing: border-box;
    white-space: break-spaces;
}

.textWrap :global div.card-front[class$='_desc'] {
    width: 100%;
    text-align: start;
    font-size: calc(var(--responsive) * 22);
    line-height: 1.200em;
    letter-spacing: -0.02em;
    font-weight: 700;
    margin-top: 1.273em;
    box-sizing: border-box;
    white-space: break-spaces;
}

/* TEXT BACKEND */

.descWrap {
    background: rgba(255, 255, 255, 0.5);
    animation: item2S_OP 1200ms ease-in-out 1;
    cursor: initial;
}

@keyframes item2S_OP {
    from { opacity: 0; }
    to { opacity: 1; } 
}

/* MEDIA */

.mediaWrap {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transition: 600ms ease-in-out;
}

.blur {
   filter: blur(25px);
   -webkit-filter: blur(25px);
}

.mediaWrap video,
.mediaWrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* __COMMON */


/* HOME */

/* HOME CARD LAYOUT */

:global .home_card01,
:global .home_card04,
:global .home_card07 {
    width: 100%;
}

:global .home_card02,
:global .home_card03,
:global .home_card05,
:global .home_card06 {
    width: calc(50% - calc(var(--responsive) * 23));
}

:global .home_card02,
:global .home_card05 {
    margin-right: calc(var(--responsive) * 46);
}

:global .home_card04 {
    height: calc(var(--responsive) * 504);
    background: #0085FF;
}

:global .home_card07 {
    margin-bottom: initial;
}

:where( :global .home_card01 ) .wrapInner {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.container:not(:global .home_card01) .descWrap .wrapInner {
    width: calc(var(--responsive) * 478);
 }

.container:not(:global .home_card01) :global .child_wrap {
   width: 100%;
}

.container:not(:global .home_card01) .descWrap div:nth-child(2) {
    margin-top: calc(var(--responsive) * 55);
}

:where( :global .home_card07 ) .descWrap div:nth-child(3) {
   margin-top: calc(var(--responsive) * 70);
} 


/* :is(:global #root) .descWrap :global .home_card01_title,
:is(:global #root) .descWrap :global .home_card02_title,
:is(:global #root) .descWrap :global .home_card03_title,
:is(:global #root) .descWrap :global .home_card04_title,
:is(:global #root) .descWrap :global .home_card05_title,
:is(:global #root) .descWrap :global .home_card06_title,
:is(:global #root) .descWrap :global .home_card07_title {
    white-space: nowrap;
 } */


/* HOME CARD 01 */

:where( :global .home_card01 ) .descWrap  div:nth-child(1) {
    width: 100%;
}

:where( :global .home_card01 ) .descWrap div:nth-child(n+2) {
   width: calc(50% - calc(var(--responsive) * 71));
}

:where( :global .home_card01 ) .descWrap div:nth-child(n+2):nth-child(-n+3) {
    margin-top: calc(var(--responsive) * 55);
    margin-bottom: calc(var(--responsive) * 70);
}

:where( :global .home_card01 ) .descWrap div:nth-child(2n) {
    margin-right: calc(var(--responsive) * 142);
}

/* HOME CARD 04 */

:global .home_card04 button {
    border-color: #fff;
}

:global .home_card04 button span::after,
:global .home_card04 button span::before {
    background: #fff;
}

:global .home_card04_title,
:global .home_card04_sub_tit01,
:global .home_card04_text01 {
    color: #fff;
}

/* HOME CARD 04 */

:where( :global .home_card04 ) .descWrap {
   background: rgba(0, 133, 255, 0.5);
}

/* HOME CAND 07 */

:where(:global .home_card07) .textWrap :global div.card-front.home_card07_title {
    white-space: initial;
}

/* __HOME */

/* ABOUT */

:where(:global .about_card02) .textWrap :global div.card-front[class$='_title'] {
    width: calc(var(--responsive) * 550);
}

:where( :global .about_card01 ) .mediaWrap img {
    position: absolute;
    left: 50%;
    height: 100%;
    width: auto;
}

:where( :global .about_card02 ) .mediaWrap img {
    position: absolute;
    left: calc(var(--responsive) * 430);
    height: 114%;
    width: auto;
}


/* __ABOUT */


/* RESPONSIVE */
@media (max-width: 912px) {

    .container {
        font-size: calc(var(--responsive) * 100);
        height: calc(var(--responsive) * 410);
        border-radius: calc(var(--responsive) * 20);
        box-shadow: 0 0.060em 0.160em rgba(0, 0, 0, 0.1);
        overflow: hidden;
        transform: translateZ(0);
        will-change: transform;
    }

    .container:not(:global .home_card07) {
        margin-bottom: calc(var(--responsive) * 24);
    }

    .cardBtn {
        left: calc(var(--responsive) * 30);
        bottom: calc(var(--responsive) * 30);
        width: calc(var(--responsive) * 28);
        height: calc(var(--responsive) * 28);
        border-width: 1.5px;
    }
    
    .cardBtn span {
        width: calc(var(--responsive) * 12);
        height: calc(var(--responsive) * 12);
    }

    .wrapInner {
        margin: calc(var(--responsive) * 30);
        margin-top: calc(var(--responsive) * 50);
    }

    .cardBtn span::after {
        width: 1.5px;
    }
    
    .cardBtn span::before {
        height:1.5px;
    }

    /* TEXT FRONT */

    .textWrap :global div.card-front[class$='_title'] {
        font-size: calc(var(--responsive) * 38);
    }

    .textWrap :global div.card-front[class$='_desc'] {
        font-size: calc(var(--responsive) * 18);
    }

    /* HOME */

    /* HOME CARD LAYOUT */

    :global .home_card02,
    :global .home_card03,
    :global .home_card05,
    :global .home_card06 {
        width: 100%;
    }

    :global .home_card02,
    :global .home_card05 {
        margin-right: initial;
    }

    :global .home_card01 {
        height: calc(var(--responsive) * 778);
    }

    :global .home_card04 {
        height: calc(var(--responsive) * 382);
    }

    :global .home_card07 {
        height: calc(var(--responsive) * 560);
    }

    :where( :global .home_card01 ) .wrapInner {
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
    }
    
    .container:not(:global .home_card01) .wrapInner {
        width: 100%;
    }

    .container:not(:global .home_card01) .descWrap .wrapInner {
        width: 100%;
     }

    .container .descWrap div:nth-child(2) {
        margin-top: calc(var(--responsive) * 24) !important;
    }

    :where( :global .home_card07 ) .descWrap div:nth-child(3) {
        margin-top: calc(var(--responsive) * 32);
    } 

    /* HOME CARD 01 */

    :where( :global .home_card01 ) .descWrap div:nth-child(n+2) {
        width: 100%;
    }

    :where( :global .home_card01) .descWrap div:nth-child(n+2):nth-child(-n+3) {
        margin-top: initial;
        margin-bottom: initial;
    }

    :where( :global .home_card01 ) .descWrap div:nth-child(2n) {
        margin-right: initial
    }

    /* 임시 */
    :where( :global .home_card01 ) .mediaWrap video {}

    /* HOME CARD 02 */
    :where( :global .home_card02 ) .mediaWrap video {
        margin-top: 22%;
        width: 100%;
        height: auto;
    }

    /* HOME CARD 03 */
    :where( :global .home_card03 ) .mediaWrap img {
        display: block;
        width: 98%;
        margin-top: 7%;
        margin-left: 5%;
        object-fit: contain;
    }

    /* HOME CARD 05 */
    :where( :global .home_card06 ) .mediaWrap img {
        display: block;
        object-fit: contain;
    }

    /* HOME CARD 07 */

    :where(:global .home_card07) .textWrap :global div.card-front.home_card07_title {
        white-space: break-spaces;
    }

    /* __HOME */
    

    /* ABOUT */

    :where(:global .about_card02) .textWrap :global div.card-front[class$='_title'] {
        width: auto;
    }

    :where( :global .about_card01 ) .mediaWrap img {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height:auto;
    }

    :where(:global .about_card01) .cardBtn {
        border-color: #fff;
    }

    :where(:global .about_card01) .cardBtn span::after {
        background: #fff;
    }

    :where(:global .about_card01) .cardBtn span::before {
        background: #fff;
    }

    :where(:global .about_card01 button).inactive {
        border-color: #565656;
    }
    
    :where(:global .about_card01) .inactive span::after, .inactive span::before {
        background: #565656;
    }

    :where( :global .about_card02 ) .mediaWrap img {
        position: absolute;
        top: 50%;
        left: 0;
        width: 120%;
        height:auto;
    }


    /* EN, JP */

    .container.otherLang .textWrap :global div.card-front[class$='_title'] {
        font-size: calc(var(--responsive) * 28);
    }


}