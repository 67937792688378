.container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container ul {
    width: 100%;
    border-top: 1px solid #999;
    margin-bottom: calc(var(--responsive) * 255);
}

.specsList {
    width: 100%;
    border-bottom: 1px solid #999;
    display: flex;
    padding-top: calc(var(--responsive) * 44);
    padding-bottom: calc(var(--responsive) * 44);
}

.listName {
    width: calc(var(--responsive) * 276);
}

.listName h3 {
    width: 100%;
    text-align: start;
    font-size: calc(var(--responsive) * 24);
    line-height: 1.200em;
    letter-spacing: -0.02em;
    font-weight: 600;
}

.listText {
    width: calc(var(--responsive) * 888);
    font-size: calc(var(--responsive) * 14);
    margin-left: 2.143em;
}

.listText p {
    display: inline-block;
    width: 100%;
    font-size: 1.00em;
    line-height: 2.143em;
    letter-spacing: -0.02em;
    font-weight: 400;
    white-space: pre;
}

.listText span {
    display: inline-block;
    font-size: calc(var(--responsive) * 13);
    letter-spacing: -0.02em;
    color: #747474;
    margin-top: 0.462em;
    margin-bottom: 2.000em;
}

/* RESPONSIVE */
@media (max-width: 912px) {

    /* INIT */
    .container ul {
        border-top: initial;
    }

    .specsList {
        border-bottom: initial;
    }

    .listText {
        margin-left: initial;
    }

    /* _INIT */

    .container ul {
        margin-bottom: calc(var(--responsive) * 90);
    }

    .specsList {
        flex-direction: column;
    }

    .listName {
        width: 100%;
        border-bottom: 1px solid #999;
    }

    .listName h3 {
        font-size: calc(var(--responsive) * 18);
        margin-bottom: 0.889em;
    }

    .listText {
        width: 100%;
        font-size: calc(var(--responsive) * 15);
        margin-top: 1.600em;
    }

    .listText p {
        line-height: 1.333em;
    }

    .listText span {
        font-size: 1.00em;
        line-height: 1.333em;
        margin-top: 0.267em;
        margin-bottom: 0.533em;
    }
    

}