.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(315.49deg, #F0F2F7 -15.33%, #F6F7FA 107.06%);
}

.innerWrap {
    width: calc(var(--responsive) * 1194);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleWrap {
    margin-top: calc(var(--responsive) * 141);
    margin-bottom: calc(var(--responsive) * 53);
}

.titleWrap h3 {
    text-align: center;
    font-size: calc(var(--responsive) * 60);
    font-weight: 700;
    line-height: 1.317em;
}

.titleWrap h3 span {
    color: #0085FF;
}

.imgWrap {
    width: calc(var(--responsive) * 600);
    margin-bottom: calc(var(--responsive) * 239);
}

.imgWrap img {
    width: 100%;
    object-fit: cover;
}

.infoWrap {
    width: 100%;
    text-align: start;
    margin-bottom: calc(var(--responsive) * 288);
}

.infoWrap span {
    display: inline-block;
    width: 100%;
    font-size: calc(var(--responsive) * 22);
    line-height: 1.182em;
    letter-spacing: -0.02em;
    font-weight: 700;
    color: #808080;
}

.infoWrap h3 {
    display: inline-block;
    width: 100%;
    font-size: calc(var(--responsive) * 54);
    line-height: 1.204em;
    font-weight: 700;
    margin-top: 0.333em;
    white-space: pre;
}

.infoWrap p {
    display: inline-block;
    width: 100%;
    font-size: calc(var(--responsive) * 24);
    line-height: 1.200em;
    font-weight: 600;
    margin-top: 2.292em;
}

/* RESPONSIVE */
@media (max-width: 912px) {

    .innerWrap {
        width: calc(var(--responsive) * 312);
    }

    .titleWrap {
        margin-top: calc(var(--responsive) * 104);
        margin-bottom: calc(var(--responsive) * 5);
    }

    .titleWrap h3 {
        font-size: calc(var(--responsive) * 32);
        line-height: 1.200em;
    }

    .imgWrap {
        width: calc(var(--responsive) * 264);
        margin-bottom: calc(var(--responsive) * 179);
    }

    .infoWrap {
        margin-bottom: calc(var(--responsive) * 94);
    }

    .infoWrap span {
        font-size: calc(var(--responsive) * 20);
        line-height: 1.050em;
    }

    .infoWrap h3 {
        font-size: calc(var(--responsive) * 32);
        line-height: 1.188em;
        margin-top: 0.250em;
    }

    .infoWrap p {
        font-size: calc(var(--responsive) * 15);
        line-height: 1.400em;
        font-weight: 500;
        margin-top: 1.600em;
    }
    
}