.container [class$='_title'] {
    display: block;
    width: 100%;
    text-align: start;
    font-size: calc(var(--responsive) * 32);
    line-height: 1.200em;
    letter-spacing: -0.02em;
    font-weight: 700;
    box-sizing: border-box;
    /* white-space: nowrap; */
    /* white-space: break-spaces; */
}

.container [class$='_sub_tit01'],
.container [class$='_sub_tit02'],
.container [class$='_sub_tit03'],
.container [class$='_sub_tit04'] {
    display: block;
    width: 100%;
    text-align: start;
    font-size: calc(var(--responsive) * 22);
    line-height: 1.200em;
    letter-spacing: -0.02em;
    font-weight: 700;
    margin-bottom: 0.818em;
    box-sizing: border-box;
    /* white-space: break-spaces; */
}

.container [class$='_text01'],
.container [class$='_text02'],
.container [class$='_text03'],
.container [class$='_text04'] {
    display: block;
    width: 100%;
    text-align: start;
    font-size: calc(var(--responsive) * 16);
    line-height: 1.313em;
    letter-spacing: -0.02em;
    font-weight: 400;
    box-sizing: border-box;
    /* white-space: break-spaces; */
}


@media (max-width: 912px) {
    .container [class$='_title'] {
        font-size: calc(var(--responsive) * 22);
    }

    .container [class$='_sub_tit01'],
    .container [class$='_sub_tit02'],
    .container [class$='_sub_tit03'],
    .container [class$='_sub_tit04'] {
        font-size: calc(var(--responsive) * 18);
        line-height: 1.200em;
        margin-bottom: 0.889em;
    }

    .container [class$='_text01'],
    .container [class$='_text02'],
    .container [class$='_text03'],
    .container [class$='_text04'] {
        font-size: calc(var(--responsive) * 15);
        line-height: 1.400em;
    }

    /* HOME CARD 01 */
    .container [class^='home_card01_text'] {
        margin-bottom: calc(var(--responsive) * 32);
    }

    /* ABOUT CARD 02 */
    .container [class^='about_card01_title'] {
        white-space: initial;
    }

    /* EN, JP */

    .container.otherLang [class$='_title'] {
        font-size: calc(var(--responsive) * 20);
    }

    .container.otherLang :is(
        [class$='_sub_tit01'], 
        [class$='_sub_tit02'], 
        [class$='_sub_tit03'], 
        [class$='_sub_tit04']) {
            font-size: calc(var(--responsive) * 15);
            margin-bottom: 0.4em;
        }

    .container.otherLang :is(
        [class$='_text01'], 
        [class$='_text02'], 
        [class$='_text03'], 
        [class$='_text04']) {
            font-size: calc(var(--responsive) * 12);
            line-height: 1.25em;
        }
}
